export const getLocaleFormatted = (locale: string) => {
  const [lang, country] = locale.split('-');

  return `${lang}_${country?.toUpperCase()}`;
};

// Function to sanitize the CategoryExternalId value
export const sanitizeCategoryExternalId = (value: string): string => {
  // Remove special characters, allowing only spaces or hyphens
  const sanitizedValue = value.replace(/[^a-zA-Z\d\s-]/g, '');
  return sanitizedValue.trim(); // Trim leading and trailing spaces
};
