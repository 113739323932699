import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { keyboardAccessibility } from '@/utils/keyboardAccessibility';

export const KeyboardAccessibility = () => {
  const router = useRouter();

  useEffect(() => {
    keyboardAccessibility();
  }, [router.asPath]);

  return <></>;
};

export default KeyboardAccessibility;
