import type { NextRouter } from 'next/router';
import { localeToStore, storeLocale } from '@/root/constants';
import { sanitizeHandle } from '@/utils/helpers';
import { getSanitizedPath } from '../getSanitizedPath/getSanitizedPath';

/**
 * Generates specific SEO-related data based on the provided store URL and router state.
 *
 * @param {string | undefined} storeUrl - The base URL of the store.
 * @param {NextRouter} router - The router object from Next.js.
 *
 * @returns {Object} Returns an object with the following properties:
 *  - seoPath (string): The primary SEO-friendly path derived from the current route and sanitized parameters.
 *  - seoPathAlt (string): An alternative SEO-friendly path prefixed with `/en-GB`.
 *  - ogType (string): The Open Graph type for the page, which can be 'website' or 'article'.
 *  - siteName (string): Name of the site, which can be 'OPI UK' or 'OPI' based on the locale.
 */

export const getSeoData = (
  storeUrl: string | undefined,
  router: NextRouter
) => {
  const { locale, asPath, route, query } = router;

  const sanitizedPath = getSanitizedPath(asPath);

  const handle: string | undefined = Array.isArray(query.handle)
    ? query.handle[query.handle.length - 1]
    : query.handle;
  const sanitizedHandle = sanitizeHandle(handle ?? '');

  let seoPath = `${storeUrl}${sanitizedPath}`;
  let seoPathAlt = `${storeUrl}/${storeLocale(locale)}${sanitizedPath}`;
  let ogType = 'website';
  const siteLocale = storeLocale(locale);
  const siteName = localeToStore(siteLocale);

  if (route.includes('/collections/') && sanitizedHandle) {
    seoPath = `${storeUrl}/collections/${sanitizedHandle}`;
    seoPathAlt = `${storeUrl}/${storeLocale(
      locale
    )}/collections/${sanitizedHandle}`;
  } else if (route.includes('/products/') && sanitizedHandle) {
    seoPath = `${storeUrl}/products/${sanitizedHandle}`;
    seoPathAlt = `${storeUrl}/${storeLocale(
      locale
    )}/products/${sanitizedHandle}`;
  } else if (route.includes('/nail-art/') && sanitizedHandle) {
    seoPath = `${storeUrl}/nail-art/${sanitizedHandle}`;
    seoPathAlt = `${storeUrl}/${storeLocale(
      locale
    )}/nail-art/${sanitizedHandle}`;
    ogType = 'article';
  }

  return { seoPath, seoPathAlt, ogType, siteName };
};
