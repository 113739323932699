import { QueryClient } from '@tanstack/react-query';
import {
  extractDiscountCodeFromCart,
  INVALID_DISCOUNT_CODE,
} from '@/backend/utils/discount';
import type {
  StoreAttribute,
  StoreCart,
  StoreContext,
} from '@/components/storeContext/storeMachine';
import { useCartLineAddMutation } from '@/lib/shopify-storefront/__generated__/CartLineAddMutation';
import { useCartLineRemoveMutation } from '@/lib/shopify-storefront/__generated__/CartLineRemoveMutation';
import { useCartLineUpdateMutation } from '@/lib/shopify-storefront/__generated__/CartLineUpdateMutation';
import type { FGwpPromotionsQuery } from '@/lib/shopify-storefront/__generated__/FGWPPromotions';
import { useFGwpPromotionsQuery } from '@/lib/shopify-storefront/__generated__/FGWPPromotions';
import type {
  AttributeInput,
  CartLineInput,
  CartLineUpdateInput,
} from '@/lib/shopify-storefront/__generated__/types';
import {
  storefrontMutationDataSource,
  storefrontQueryDataSource,
} from '@/lib/shopify-storefront/dataSources';
import { storeLocale } from '@/root/constants';

const defaultHeaders = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'content-type': 'application/json;charset=UTF-8',
};

type FGWPPromotion = {
  /**
   * Free Gift With Purchase promo code for consumer customers.
   */
  consumerCode: string;
  /**
   * Free Gift With Purchase promo code for pro customers.
   */
  proCode: string;
  /**
   * Free Gift With Purchase promo array of gifts gid (Graphql Id) for consumer customer.
   */
  consumerGifts: Array<string>;
  /**
   * Free Gift With Purchase promo array of gifts gid (Graphql Id) for pro customer.
   */
  proGifts: Array<string>;
};

/**
 * Free Gift With Purchase Settings Type.
 * - Array of active, formatted Free Gift with Purchase promotion settings
 * - Settings are fetched from Shopify metaobjects
 * - Used by cart to add/remove gift products
 */
type FGWPPromotions = Array<FGWPPromotion>;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Refetch if 1 hour passed even if not stale.
      // It also refetch after page reload.
      staleTime: 1000 * 60 * 60 * 1,
    },
  },
});

export const KEY_DISCOUNT = 'discount';
export const KEY_GIFT = '_gift';

export function modifyDiscountCodeAttribute(
  attributes: StoreAttribute[],
  code: string | null
): AttributeInput[] {
  const withRemovedDiscount = attributes.filter(
    (attribute) => attribute.key !== KEY_DISCOUNT
  );

  if (!code || code === INVALID_DISCOUNT_CODE) {
    return withRemovedDiscount as AttributeInput[];
  }

  return [
    ...(withRemovedDiscount as AttributeInput[]),
    {
      key: KEY_DISCOUNT,
      value: code,
    },
  ];
}

export const getGiftsHandlerMutation = async (
  context: StoreContext,
  cart: StoreCart
) => {
  const { customer, store } = context;
  const proCustomer = Boolean(customer?.[store]?.pro);
  let appliedPromotion: FGWPPromotion | undefined = undefined;

  try {
    const fgwpPromotionsQuery = await queryClient.fetchQuery({
      queryKey: useFGwpPromotionsQuery.getKey(),
      queryFn: useFGwpPromotionsQuery.fetcher(
        storefrontQueryDataSource(context.locale)
      ),
    });

    /**
     * Free Gift With Purchase Settings
     * - Array of active, formatted Free Gift with Purchase promotion settings
     * - Settings are fetched from Shopify metaobjects
     * - Used by cart to add/remove gift products
     */
    const fgwpPromotions = fgwpPromotionsSelect(fgwpPromotionsQuery);

    appliedPromotion = getAppliedPromotion(proCustomer, cart, fgwpPromotions);
  } catch (error) {
    console.warn(error);
  }

  const isEligible = getIsEligible(proCustomer, cart, appliedPromotion);

  const variantsIds = proCustomer
    ? appliedPromotion?.proGifts
    : appliedPromotion?.consumerGifts;

  const discountCode = extractDiscountCodeFromCart(cart);

  // Promotion gifts to add
  const linesAsGiftToAdd =
    variantsIds?.map((variantId) => {
      return {
        merchandiseId: variantId,
        quantity: 1,
        attributes: modifyDiscountCodeAttribute(
          [
            {
              key: KEY_GIFT,
              value: 'true',
            },
          ],
          discountCode
        ),
      };
    }) ?? [];

  // Promotion gifts to add. For every variant, fetch the tags
  const linesToAddPromises =
    linesAsGiftToAdd?.map(async (lineItem) => {
      const response = await window.fetch(
        `${window.location.origin}/api/product-variant-tags`,
        {
          method: 'POST',
          headers: defaultHeaders,
          body: JSON.stringify({
            productVariant: lineItem.merchandiseId,
            locale: storeLocale(context.locale),
          }),
        }
      );

      const res = await response.json();

      return {
        ...lineItem,
        attributes: [
          ...lineItem.attributes,
          ...(res.tags.includes('free-gift')
            ? [
                {
                  key: 'marketing',
                  value: 'true',
                },
              ]
            : []),
        ],
      };
    }) ?? [];

  // Use Promise.all to wait for all fetch operations to complete
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linesToAdd = await Promise.all(linesToAddPromises as any);

  // Promotion gifts to update (enforces 1 quantity per gift)
  const linesToUpdate = cart.lines
    .filter(
      (line) =>
        variantsIds?.includes(line.merchandise.id) &&
        line.attributes.some((attribute) => attribute.key === KEY_GIFT) &&
        line.quantity > 1
    )
    .map<CartLineUpdateInput>((line) => {
      return {
        id: line.id,
        merchandiseId: line.merchandise.id,
        quantity: 1,
        attributes: modifyDiscountCodeAttribute(
          [
            {
              key: KEY_GIFT,
              value: 'true',
            },
          ],
          discountCode
        ),
      };
    });

  // Promotion gifts to remove
  const lineIdsToRemove = cart.lines
    .filter(
      (line) =>
        !variantsIds?.includes(line.merchandise.id) &&
        line.attributes.some((attribute) => attribute.key === KEY_GIFT)
    )
    .map((line) => line.id);

  // Are all or some gifts already in cart.
  // Also we check if gift quantity is 1.
  const giftsAlreadyInCart = cart.lines.some(
    (line) =>
      variantsIds?.includes(line.merchandise.id) &&
      line.attributes.some((attribute) => attribute.key === KEY_GIFT) &&
      line.quantity === 1
  );

  if (giftsAlreadyInCart) return;

  return await getCartLineMutation(
    context,
    isEligible,
    linesToAdd,
    linesToUpdate,
    lineIdsToRemove
  );
};

const getAppliedPromotion = (
  proCustomer: boolean,
  cart: StoreCart,
  fgwpPromotions?: FGWPPromotions
) => {
  const applicableCodes = cart?.discountCodes
    .filter((discountCode) => discountCode.code && discountCode.applicable)
    .map((discountCode) => discountCode.code.toLowerCase());

  return proCustomer
    ? fgwpPromotions?.find((promotion) =>
        applicableCodes.includes(promotion.proCode.toLowerCase())
      )
    : fgwpPromotions?.find((promotion) =>
        applicableCodes.includes(promotion.consumerCode.toLowerCase())
      );
};

const getIsEligible = (
  proCustomer: boolean,
  cart: StoreCart,
  appliedPromotion: FGWPPromotion | undefined
) => {
  const applicableCodes = cart?.discountCodes
    .filter((discountCode) => discountCode.code && discountCode.applicable)
    .map((discountCode) => discountCode.code.toLowerCase());

  if (
    !appliedPromotion?.proCode.toLowerCase() &&
    !appliedPromotion?.consumerCode.toLowerCase()
  )
    return false;

  return proCustomer
    ? applicableCodes.includes(appliedPromotion?.proCode.toLowerCase())
    : applicableCodes.includes(appliedPromotion?.consumerCode.toLowerCase());
};

export const getCartLineMutation = (
  context: StoreContext,
  isEligible: boolean,
  linesToAdd: CartLineInput[],
  linesToUpdate: CartLineUpdateInput[],
  lineIdsToRemove: string[]
) => {
  if (!linesToUpdate.length && isEligible) {
    return useCartLineAddMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: context.cartId?.[context.store] ?? '',
        linesToAdd,
        lineIdsToRemove,
      })()
      .then((data) => data.cartLinesAdd);
  }

  if (linesToUpdate.length) {
    return useCartLineUpdateMutation
      .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
        cartId: context.cartId?.[context.store] ?? '',
        linesToAdd,
        linesToUpdate,
        lineIdsToRemove,
      })()
      .then((data) => data.cartLinesUpdate);
  }

  if (!lineIdsToRemove.length) {
    return;
  }

  return useCartLineRemoveMutation
    .fetcher(storefrontMutationDataSource(storeLocale(context.locale)), {
      cartId: context.cartId?.[context.store] ?? '',
      lineIdsToRemove,
    })()
    .then((data) => data.cartLinesRemove);
};

export const fgwpPromotionsSelect = (
  originalData: FGwpPromotionsQuery
): FGWPPromotions | undefined => {
  if (!originalData?.metaobject?.list?.references?.nodes.length) {
    return;
  }

  const fgwpPromotions: FGWPPromotions = [];
  const rawFGWPPromotions = originalData?.metaobject?.list?.references?.nodes;

  rawFGWPPromotions.forEach((promotion) => {
    if (promotion.__typename !== 'Metaobject') return;

    const consumerCode = promotion.consumerCode?.value ?? '';
    const proCode = promotion.proCode?.value ?? '';

    const rawProGifts = promotion.proGifts?.value ?? '[]';
    const rawConsumerGifts = promotion.consumerGifts?.value ?? '[]';

    let proGifts = [];
    let consumerGifts = [];

    try {
      proGifts = JSON.parse(rawProGifts);
      consumerGifts = JSON.parse(rawConsumerGifts);
    } catch (error) {
      console.warn('Error parsing gifts array', error);
    }

    fgwpPromotions.push({
      consumerCode,
      proCode,
      consumerGifts,
      proGifts,
    });
  });

  return fgwpPromotions;
};
