import { useQuery } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import type { ImageType } from '@/components/plpContext/types/storePLP';
import type { BannerFragment } from '@/lib/contentful/__generated__/Banner';
import type { GetFooterQuery } from '@/lib/contentful/__generated__/FooterQuery';
import { useGetFooterQuery } from '@/lib/contentful/__generated__/FooterQuery';
import { useGetGlobalMastheadQuery } from '@/lib/contentful/__generated__/MastHeadQuery';
import type { GetGlobalMastheadQuery } from '@/lib/contentful/__generated__/MastHeadQuery';

import type {
  Maybe,
  NavigationBlock,
  NavigationItem,
} from '@/lib/contentful/__generated__/types';
import { contentfulQueryDataSource } from '@/lib/contentful/dataSources';
import type { StoreLocale } from '@/root/constants';

export interface MediaType {
  title: string;
  url: string;
  newTab: boolean;
  image: ImageType;
}

export interface MediaBlockType {
  title?: string;
  images: MediaType[];
}

export type StoreMenuItem = {
  id: string;
  title: string;
  url?: string;
  newTab?: boolean;
  image?: ImageType | undefined;
  medias?: MediaBlockType;
  menu?: StoreMenuItem[];
  external?: boolean | undefined;
};

export type StorePromoBannerItem = {
  text: string;
  link: string;
  newTab: boolean;
};

export type StorePromoBanner = {
  promos: StorePromoBannerItem[] | undefined;
  backgroundColor: string;
  textColor: string;
};

export type MenuContext = {
  promoBanner?: StorePromoBanner;
  promoBannerProContent?: StorePromoBanner;
  menus?: Record<string, StoreMenuItem[]>;
};

export const MenuContext = createContext<MenuContext>({ menus: {} });

export function MenuProvider({
  locale,
  children,
}: {
  locale: StoreLocale;
  children: ReactNode;
}) {
  const { data: mastHead } = useQuery(
    useGetGlobalMastheadQuery.getKey({ locale }),
    useGetGlobalMastheadQuery.fetcher(contentfulQueryDataSource({}), {
      locale,
    }),
    {
      select: getGlobalMastheadQuerySelect,
    }
  );

  const { data: footerMenu = [] } = useQuery(
    useGetFooterQuery.getKey({ locale: locale }),
    useGetFooterQuery.fetcher(contentfulQueryDataSource({}), {
      locale: locale,
    }),
    {
      select: getFooterMenuQuerySelect,
    }
  );

  // memoize the full context value
  const contextValue = useMemo(() => {
    const menus: Record<string, StoreMenuItem[]> = {
      desktop: [],
      mobile: [],
      footer: [],
      legal: [],
    };

    if (footerMenu.length) {
      menus.footer = footerMenu;
    }

    if (mastHead?.menus.desktop.length) {
      menus.desktop = mastHead.menus.desktop;
    }

    if (mastHead?.menus.mobile.length) {
      menus.mobile = mastHead.menus.mobile;
    }

    const promoBanner = mastHead?.promoBanner;
    const promoBannerProContent = mastHead?.promoBannerProContent;

    return {
      promoBanner,
      menus,
      promoBannerProContent,
    };
  }, [footerMenu, mastHead]);

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
}

/**
 * Select function that acts as 'clean' to GetFooterQuery
 *
 * @remarks
 * Data from GetFooterQuery is reduced into the shape of StoreMenuItem[]
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts GetFooterQuery
 */
const getFooterMenuQuerySelect = (originalData: GetFooterQuery) => {
  if (!originalData.navigationFooterMenuCollection?.items[0]) {
    return [];
  }

  const footerMenuData = originalData.navigationFooterMenuCollection?.items[0];
  const footerMenu: StoreMenuItem[] = [];

  const rawNavData =
    footerMenuData?.footerNavigationItemCollection?.items[0]
      ?.nestedNavigationItemCollection?.items || [];

  rawNavData.forEach((footerNavHeader, index) => {
    footerMenu.push({
      id: `${index}`,
      title: footerNavHeader?.text || '',
      url: footerNavHeader?.url || '',
      newTab: footerNavHeader?.openLinkInNewTab || false,
      menu: footerNavHeader?.nestedNavigationItemCollection?.items.map(
        (menuItem, index) => {
          return {
            id: `${index}`,
            title: menuItem?.text || '',
            url: menuItem?.url || '',
            newTab: menuItem?.openLinkInNewTab || false,
          } as StoreMenuItem;
        }
      ) as StoreMenuItem[],
    });
  });

  return footerMenu;
};

const cleanNestedNavigation = (
  navItems: Maybe<NavigationItem>[]
): StoreMenuItem[] => {
  const menuItems: StoreMenuItem[] = [];

  navItems.forEach((item) => {
    if (!item) return;

    const subMenu = item.nestedNavigationItemCollection?.items;

    menuItems.push({
      id: item.sys.id ?? '',
      title: item.text ?? '',
      url: item.url ?? '',
      newTab: item.openLinkInNewTab ?? false,
      image: item.image
        ? {
            title: item.image.title ?? '',
            url: item.image.url ?? '',
            height: item.image.height ?? 0,
            width: item.image.width ?? 0,
          }
        : undefined,
      menu: subMenu ? cleanNestedNavigation(subMenu) : undefined,
    });
  });

  return menuItems;
};

const cleanNestedNavigationBlocks = (
  navBlocks: Maybe<NavigationBlock>[]
): MediaBlockType | undefined => {
  if (!navBlocks.length) return undefined;

  const medias: MediaBlockType = {
    title: '',
    images: [],
  };

  const navBlock = navBlocks[0];
  if (!navBlock) return undefined;

  medias.title = navBlock.title ?? '';

  const blockMediaItems = navBlock.blockMediaCollection?.items;
  if (!blockMediaItems) return undefined;

  blockMediaItems.forEach((item) => {
    if (!item) return;

    medias.images.push({
      title: item.text ?? '',
      url: item.url ?? '',
      newTab: item.openLinkInNewTab ?? false,
      image: {
        title: item.blockImage?.title ?? '',
        url: item.blockImage?.url ?? '',
        height: item.blockImage?.height ?? 0,
        width: item.blockImage?.width ?? 0,
      },
    });
  });

  return medias;
};

/**
 * Select function that acts as 'clean' to GetGlobalMastheadQuery
 *
 * @remarks
 * Data from GetGlobalMastheadQuery is reduced into the shape of MenuContext
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts GetBannerQuery
 */
const getGlobalMastheadQuerySelect = (originalData: GetGlobalMastheadQuery) => {
  if (!originalData.globalMastheadCollection?.items[0]) return undefined;

  const bannerData = originalData.globalMastheadCollection?.items[0]?.promo;
  const proBannerData =
    originalData.globalMastheadCollection?.items[0]?.promoProContent;

  const desktopMainMenu = formatMainMenu(originalData, 'navLinks');
  const mobileMainMenu = formatMainMenu(originalData, 'mobileNavLinks');

  const customerRefinedBannerData = formatBanner({ banner: bannerData });
  const proRefinedBannerData = formatBanner({
    banner: proBannerData || bannerData,
  });

  return {
    promoBanner: customerRefinedBannerData,
    menus: {
      desktop: desktopMainMenu,
      mobile: mobileMainMenu,
    },
    promoBannerProContent: proRefinedBannerData,
  };
};

const formatBanner = ({
  banner,
}: {
  banner: BannerFragment | undefined | null;
}) => {
  const promos: StorePromoBannerItem[] = [];

  const items = banner?.bannerItemCollection?.items || [];
  const backgroundColor = banner?.backgroundColor || '';
  const textColor = banner?.textColor || '';

  items.forEach((item) => {
    if (!item) return;

    promos.push({
      text: item.bannerItemText ?? '',
      link: item.bannerItemLink ?? '',
      newTab: item.openLinkInNewTab ?? false,
    });
  });

  return {
    promos,
    backgroundColor,
    textColor,
  };
};

const formatMainMenu = (
  originalData: GetGlobalMastheadQuery,
  key: 'navLinks' | 'mobileNavLinks'
) => {
  if (
    !originalData?.globalMastheadCollection?.items[0]?.[key]
      ?.navigationItemCollection?.items
  )
    return [];

  const desktopMainMenu: StoreMenuItem[] = [];
  const menuItems = originalData.globalMastheadCollection?.items[0]?.[key]
    ?.navigationItemCollection?.items as Maybe<NavigationItem>[];

  menuItems.forEach((item) => {
    if (!item) return;

    const menu: StoreMenuItem[] = [];
    let medias: MediaBlockType | undefined = undefined;

    const nestedItems = item.nestedNavigationItemCollection?.items;

    const blockItems = item.navigationBlockCollection?.items;

    if (nestedItems) {
      menu.push(...cleanNestedNavigation(nestedItems));
    }

    if (blockItems) {
      medias = cleanNestedNavigationBlocks(blockItems);
    }

    desktopMainMenu.push({
      id: item?.sys.id ?? '',
      title: item.text ?? '',
      url: item.url ?? '',
      newTab: item.openLinkInNewTab ?? false,
      menu,
      medias,
    });
  });

  return desktopMainMenu;
};
