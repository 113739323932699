import type { CustomerWishlistFragment } from '@/lib/shopify-storefront/__generated__/CustomerWishlistFields';

export const formatWishlistData = (customer: CustomerWishlistFragment) => {
  const productsRaw = customer?.wishlistProducts?.value ?? '[]';
  const products = JSON.parse(productsRaw) as string[];

  const nailArtRaw = customer?.wishlistNailArt?.value ?? '[]';
  const nailArt = JSON.parse(nailArtRaw) as string[];

  const articlesRaw = customer?.wishlistArticles?.value ?? '[]';
  const articles = JSON.parse(articlesRaw) as string[];

  return {
    products,
    nailArt,
    articles,
  };
};
