import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'use-context-selector';
import { OverlayContext } from '@/components/overlay/OverlayContext';
import type { StoreState } from '@/components/storeContext/StoreContext';
import { StoreContext } from '@/components/storeContext/StoreContext';
import type {
  Maybe,
  StoreCart,
  StoreErrors,
} from '@/components/storeContext/storeMachine';
import type { StoreLocale, Stores } from '@/root/constants';

export type CartContextType = {
  cart: Maybe<StoreCart>;
  cartId: Maybe<string>;
  cartErrors: StoreErrors;
  cartLoading: boolean;
  quickAddedProductId: string;
  cartQuickAdd(payload: { productId: string }): void;
  cartLinesAdd(payload: { id: string; quantity: number }[]): void;
  cartLineAdd(
    payload: { id: string; quantity: number },
    toggleUpSells?: boolean
  ): void;
  /**
   * Used in Quick Shop Tray and Recommended Essentials.
   * When product added, cart drawer do not open, cause
   * it doesn't set addingProduct to true.
   */
  cartLineAddV2(payload: {
    productId?: string;
    id: string;
    quantity: number;
  }): void;
  cartLineRemove(payload: { ids: string | string[] }): void;
  cartLineUpdate(payload: { id: string; quantity: number }): void;
  cartLocaleUpdate(payload: { locale: StoreLocale; store: Stores }): void;
  cartDiscountCodesUpdate(payload: {
    id: string;
    discountCodes: string[];
  }): void;
};

export const CartContext = createContext<CartContextType>({
  cart: null,
  cartId: null,
  cartErrors: null,
  cartLoading: false,
  quickAddedProductId: '',
  cartQuickAdd: () => {
    return;
  },
  cartLineAdd: () => {
    return;
  },
  cartLineAddV2: () => {
    return;
  },
  cartLinesAdd: () => {
    return;
  },
  cartLineRemove: () => {
    return;
  },
  cartLineUpdate: () => {
    return;
  },
  cartLocaleUpdate: () => {
    return;
  },
  cartDiscountCodesUpdate: () => {
    return;
  },
});

const selectCartErrors = (state: StoreState) => state.context.cartErrors;

const selectCartId = (state: StoreState) =>
  state.context.cartId?.[state.context.store] ?? '';

const selectCart = (state: StoreState) =>
  state.context.cart?.[state.context.store] ?? null;

const selectCartLoadingState = (state: StoreState) =>
  state.matches('cartMachine.fetching');

const selectCartIdle = (state: StoreState) =>
  state.matches('cartMachine.idle.noError');

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const { storeService } = useContext(StoreContext);
  const router = useRouter();

  // Toggle Active Header
  const { open } = useContext(OverlayContext);

  const [addingProduct, setAddingProduct] = useState(false);
  const [toggleUpSells, setToggleUpSells] = useState(false);
  const [quickAddedProductId, setQuickAddedProductId] = useState('');

  const cartErrors = useSelector(storeService, selectCartErrors);
  const cartId = useSelector(storeService, selectCartId);
  const cart = useSelector(storeService, selectCart);
  const cartLoading = useSelector(storeService, selectCartLoadingState);
  const cartIdle = useSelector(storeService, selectCartIdle);

  const cartQuickAdd = ({ productId }: { productId: string }) => {
    setAddingProduct(true);
    setQuickAddedProductId(productId);
    storeService.send('CART_QUICK_ADD', { payload: { productId } });
  };

  const cartLineAdd = (
    { id, quantity }: { id: string; quantity: number },
    toggleUpSells = false
  ) => {
    if (!toggleUpSells) {
      setAddingProduct(true);
    }

    setQuickAddedProductId('');
    setToggleUpSells(toggleUpSells);
    storeService.send('CART_LINE_ADD', { payload: [{ id, quantity }] });
  };

  const cartLineAddV2 = ({
    productId,
    id,
    quantity,
  }: {
    productId: string;
    id: string;
    quantity: number;
  }) => {
    setQuickAddedProductId(productId);
    storeService.send('CART_LINE_ADD', { payload: [{ id, quantity }] });
  };

  const cartLinesAdd = (lines: { id: string; quantity: number }[]) => {
    setAddingProduct(true);
    setQuickAddedProductId('');
    storeService.send('CART_LINE_ADD', { payload: lines });
  };

  const cartLineRemove = ({ ids }: { ids: string | string[] }) => {
    setQuickAddedProductId('');
    storeService.send('CART_LINE_REMOVE', { payload: { ids } });
  };

  const cartLineUpdate = ({
    id,
    quantity,
  }: {
    id: string;
    quantity: number;
  }) => {
    setQuickAddedProductId('');
    storeService.send('CART_LINE_UPDATE', { payload: { id, quantity } });
  };

  const cartDiscountCodesUpdate = ({
    id,
    discountCodes,
  }: {
    id: string;
    discountCodes: string[];
  }) => {
    storeService.send('CART_DISCOUNT_CODES_UPDATE', {
      payload: { id, discountCodes },
    });
  };

  const cartLocaleUpdate = ({
    locale,
    store,
  }: {
    locale: StoreLocale;
    store: Stores;
  }) => {
    storeService.send('LOCALE_UPDATE', { payload: { locale, store } });
  };

  /**
   * Cart draw open side effect
   *
   * When we know that a product is being added to the cart. Watch for
   * a return to the success state.
   *
   * If successful remove the set adding product state (as we know it succeded)
   * and go ahead and display the overlay
   */
  useEffect(() => {
    if (cartIdle && toggleUpSells && router.pathname.includes('/products')) {
      setToggleUpSells(false);
      open({
        ignoreDismissed: true,
        namespace: 'recommendedEssentials',
      });

      return;
    }

    if (cartIdle && addingProduct && router.pathname !== '/cart') {
      setAddingProduct(false);
      open({
        ignoreDismissed: true,
        namespace: 'cartDrawer',
      });
    }
  }, [cartIdle, addingProduct, setAddingProduct]);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartId,
        cartErrors,
        cartLoading,
        quickAddedProductId,
        cartQuickAdd,
        cartLineAdd,
        cartLinesAdd,
        cartLineAddV2,
        cartLineRemove,
        cartLineUpdate,
        cartLocaleUpdate,
        cartDiscountCodesUpdate,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;

export const useCart = () => {
  return useContext(CartContext);
};
