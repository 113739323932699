import { extractDiscountCodeFromCart } from '@/backend/utils/discount';
import type {
  StoreCart,
  StoreCartLines,
  StoreContext,
} from '@/components/storeContext/storeMachine';
import {
  getCartLineMutation,
  KEY_DISCOUNT,
  KEY_GIFT,
  modifyDiscountCodeAttribute,
} from '@/components/storeContext/utils/addGiftsMutation';
import type { CartLineUpdateInput } from '@/lib/shopify-storefront/__generated__/types';

const hasDiscountAttribute = (line: StoreCartLines) =>
  line.attributes.some((attribute) => attribute.key === KEY_DISCOUNT);

const hasDiscountAttributeApplied = (
  line: StoreCartLines,
  discountCode: string
) =>
  line.attributes.some(
    (attribute) =>
      attribute.key === KEY_DISCOUNT && attribute.value === discountCode
  );

const isGift = (line: StoreCartLines) =>
  line.attributes.some((attribute) => attribute.key === KEY_GIFT);

export async function getCartDiscountHandlerMutation(
  context: StoreContext,
  cart: StoreCart
) {
  const discountCode = extractDiscountCodeFromCart(cart);
  const linesToUpdate: CartLineUpdateInput[] = [];
  // Remove discount attribute from line items
  if (!discountCode) {
    linesToUpdate.push(
      ...cart.lines.reduce<CartLineUpdateInput[]>((acc, line) => {
        if (hasDiscountAttribute(line)) {
          acc.push({
            id: line.id,
            attributes: modifyDiscountCodeAttribute(line.attributes, null),
          });
        }
        return acc;
      }, [])
    );
  } else if (cart.discountAllocations?.length) {
    // If discount on order, mark all items
    linesToUpdate.push(
      ...cart.lines.map((line) => ({
        id: line.id,
        attributes: modifyDiscountCodeAttribute(line.attributes, discountCode),
      }))
    );
  } else {
    // If discount on product, mark only discounted products (FGWP included)
    linesToUpdate.push(
      ...cart.lines.reduce<CartLineUpdateInput[]>((acc, line) => {
        // Skip gift tagging and duplicate tagging
        if (isGift(line) || hasDiscountAttributeApplied(line, discountCode)) {
          return acc;
        }
        if (line.discountAllocations?.length) {
          acc.push({
            id: line.id,
            attributes: modifyDiscountCodeAttribute(
              line.attributes,
              discountCode
            ),
          });
        }
        return acc;
      }, [])
    );
  }

  return getCartLineMutation(context, false, [], linesToUpdate, []);
}
