import { t } from '@lingui/macro';
import type { StoreError } from '@/components/storeContext/storeMachine';
import type { CustomerUserError } from '@/lib/shopify-storefront/__generated__/types';

/**
 * Get block class (if passed as prop).
 * @param blockClass
 * @param element
 * @param modifier
 */
export const $blockClass = (
  blockClass?: string,
  element?: string,
  modifier?: string
): string => {
  if (!blockClass) {
    return '';
  }

  let elementClass = blockClass;

  if (element) {
    elementClass += `__${element}`;
  }

  if (modifier) {
    elementClass += `--${modifier}`;
  }

  return elementClass;
};

export const getCookie = (cname: string) => {
  if (typeof document === 'undefined') return '';

  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

/**
 * Sanitizies a handle string.
 * - Ensures the product, collection etc. handles are correctly formatted to
 *   prevent requests that rely on handle look-ups from failing.
 * @param handle - handle to sanitize
 * @returns sanitized handle
 */
export const sanitizeHandle = (handle: string) => {
  return handle.toLowerCase();
};

/**
 * Cleans product handle.
 * - Removes `-pro` suffix from the product handle to prevent creating URLs that
 *   link to the `pro` product object.
 * @param handle {String}- product handle
 * @returns formatted product handle
 */
export const getCleanProductHandle = (handle: string) => {
  const regex = /-pro$/;
  if (handle.match(regex)) {
    return handle.replace(regex, '');
  }

  return handle;
};

/**
 * Format STATUS values from Shopify API to human-readable strings.
 * @param status Status string from Shopify
 * @returns converted string
 */
export function statusMessage(status: string) {
  const translations: Record<string, string> = {
    ATTEMPTED_DELIVERY: t({
      id: 'statusMessage.ATTEMPTED_DELIVERY',
      message: 'Attempted delivery',
    }),
    AUTHORIZED: t({
      id: 'statusMessage.AUTHORIZED',
      message: 'Authorized',
    }),
    CANCELED: t({
      id: 'statusMessage.CANCELED',
      message: 'Canceled',
    }),
    CONFIRMED: t({
      id: 'statusMessage.CONFIRMED',
      message: 'Confirmed',
    }),
    DELIVERED: t({
      id: 'statusMessage.DELIVERED',
      message: 'Delivered',
    }),
    FAILURE: t({
      id: 'statusMessage.FAILURE',
      message: 'Failure',
    }),
    FULFILLED: t({
      id: 'statusMessage.FULFILLED',
      message: 'Fulfilled',
    }),
    IN_PROGRESS: t({
      id: 'statusMessage.IN_PROGRESS',
      message: 'In Progress',
    }),
    IN_TRANSIT: t({
      id: 'statusMessage.IN_TRANSIT',
      message: 'In transit',
    }),
    LABEL_PRINTED: t({
      id: 'statusMessage.LABEL_PRINTED',
      message: 'Label printed',
    }),
    LABEL_PURCHASED: t({
      id: 'statusMessage.LABEL_PURCHASED',
      message: 'Label purchased',
    }),
    LABEL_VOIDED: t({
      id: 'statusMessage.LABEL_VOIDED',
      message: 'Label voided',
    }),
    MARKED_AS_FULFILLED: t({
      id: 'statusMessage.MARKED_AS_FULFILLED',
      message: 'Marked as fulfilled',
    }),
    NOT_DELIVERED: t({
      id: 'statusMessage.NOT_DELIVERED',
      message: 'Not delivered',
    }),
    ON_HOLD: t({
      id: 'statusMessage.ON_HOLD',
      message: 'On Hold',
    }),
    OPEN: t({
      id: 'statusMessage.OPEN',
      message: 'Open',
    }),
    OUT_FOR_DELIVERY: t({
      id: 'statusMessage.OUT_FOR_DELIVERY',
      message: 'Out for delivery',
    }),
    PAID: t({
      id: 'statusMessage.PAID',
      message: 'Paid',
    }),
    PARTIALLY_FULFILLED: t({
      id: 'statusMessage.PARTIALLY_FULFILLED',
      message: 'Partially Fulfilled',
    }),
    PARTIALLY_PAID: t({
      id: 'statusMessage.PARTIALLY_PAID',
      message: 'Partially Paid',
    }),
    PARTIALLY_REFUNDED: t({
      id: 'statusMessage.PARTIALLY_REFUNDED',
      message: 'Partially Refunded',
    }),
    PENDING_FULFILLMENT: t({
      id: 'statusMessage.PENDING_FULFILLMENT',
      message: 'Pending',
    }),
    PENDING: t({
      id: 'statusMessage.PENDING',
      message: 'Pending',
    }),
    PICKED_UP: t({
      id: 'statusMessage.PICKED_UP',
      message: 'Displayed as Picked up',
    }),
    READY_FOR_PICKUP: t({
      id: 'statusMessage.READY_FOR_PICKUP',
      message: 'Ready for pickup',
    }),
    REFUNDED: t({
      id: 'statusMessage.REFUNDED',
      message: 'Refunded',
    }),
    RESTOCKED: t({
      id: 'statusMessage.RESTOCKED',
      message: 'Restocked',
    }),
    SCHEDULED: t({
      id: 'statusMessage.SCHEDULED',
      message: 'Scheduled',
    }),
    SUBMITTED: t({
      id: 'statusMessage.SUBMITTED',
      message: 'Submitted',
    }),
    UNFULFILLED: t({
      id: 'statusMessage.UNFULFILLED',
      message: 'Unfulfilled',
    }),
    VOIDED: t({
      id: 'statusMessage.VOIDED',
      message: 'Voided',
    }),
  };
  try {
    return translations?.[status];
  } catch (error) {
    return status;
  }
}

/**
 * Map through the customer errors from the Storefront API and format them to
 * `StoreError` type.
 */
export const mapCustomerErrors = (
  customerUserErrors: Array<CustomerUserError>
): StoreError[] => {
  if (!customerUserErrors.length) return [];
  return customerUserErrors.map((error) => {
    return {
      message: error.message,
      code: error.code ?? '',
    };
  });
};

/**
 *  Force download cross origin files.
 *  files hosting needs to allow for cross origin access,
 *  otherwise this will throw a CORS violation error.
 */
export const downloadResource = async (
  event: React.MouseEvent<HTMLAnchorElement>,
  fileUrl: string
) => {
  event.preventDefault();

  /**
   * Regex to get the filename with extension from passed url.
   */
  const filenameRegex = /[^/\\&?]+\.\w{3,4}(?=[?&].*$|$)/;
  const matches = filenameRegex.exec(fileUrl);
  const filename = matches ? matches[0] : '';

  try {
    const fileBlob = await fetch(fileUrl, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: 'cors',
    }).then((response) => response.blob());

    const blobUrl = window.URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.download = `${filename}.pdf`;
    a.href = blobUrl;
    // For Firefox
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    console.error('Error: Downloading file failed', error);
    /**
     * In case of error open url in a new tab.
     */
    window.open(fileUrl, '_blank');
  }
};

/**
 * Validates if a date is a future date or not.
 * @param date {String}- Date input value.
 */
export const futureDateValidation = (date: string) => {
  if (new Date(date) >= new Date())
    return t({
      id: 'form.futureDateValidation',
      message: 'Future date not accepted',
    });
};

/**
 * Calculate brightness value by HEX color.
 * @param color (String) The color value in HEX (for example: #000000 || #000)
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
export const colorBrightness = (color = '') => {
  const isHEX = color.indexOf('#') == 0;
  if (!isHEX) return;

  let redChannel = 0,
    greenChannel = 0,
    blueChannel = 0;

  const hasFullSpec = color.length == 7;
  const rgbChannels = color
    .substring(1)
    .match(hasFullSpec ? /(\S{2})/g : /(\S)/g);

  if (rgbChannels) {
    redChannel = parseInt(
      rgbChannels[0] + (hasFullSpec ? '' : rgbChannels[0]),
      16
    );
    greenChannel = parseInt(
      rgbChannels[1] + (hasFullSpec ? '' : rgbChannels[1]),
      16
    );
    blueChannel = parseInt(
      rgbChannels[2] + (hasFullSpec ? '' : rgbChannels[2]),
      16
    );
  }

  if (!isNaN(redChannel) && !isNaN(greenChannel) && !isNaN(blueChannel))
    return (redChannel * 299 + greenChannel * 587 + blueChannel * 114) / 1000;
};

export const getCountryCodeFromLocale = (locale: string) => {
  return locale.split('-')[1];
};

export const idFromShopifyId = (shopifyId: string) =>
  shopifyId.split('/').pop() || '';
