/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useCollectionLocaleSwitcher } from '@/components/localeSwitcher/useCollectionLocaleSwitcher';
import { useProductLocaleSwitcher } from '@/components/localeSwitcher/useProductLocaleSwitcher';
import { useGetPageType } from '@/utils/hooks/useGetPageType';
import { CH_LOCALES, storeLocale } from '@store-constants';
import styles from './LocaleSwitcher.module.scss';

interface LocaleSwitcherContainerProps {
  isMobile?: boolean;
}

interface LocaleSwitcherProps extends LocaleSwitcherContainerProps {
  path: string | Record<string, string>;
}

const generateHref = (
  locale: string,
  path: string | Record<string, string>,
  pageType: string,
  loc: string
) => {
  const basePath = typeof path === 'string' ? path : path[loc];
  const includesProduct = basePath.includes('/products/');
  const includesCollection = basePath.includes('/collections/');

  switch (pageType) {
    case 'Product details page':
      if (includesProduct) {
        const [_, productPath] = basePath.split('/products/');
        let productHandle = productPath;
        if (productPath.includes('/')) {
          const [, productHandlePart] = productPath.split('/');
          productHandle = productHandlePart;
        }
        return `${locale}/products/${productHandle}`;
      }
      return `${locale}/products/${basePath}`;
    case 'Product listing page':
      return includesCollection
        ? `${locale}${basePath}`
        : `${locale}/collections/${basePath}`;
    default:
      return `${locale}/${basePath}`;
  }
};

const LocaleSwitcher = ({ path, isMobile }: LocaleSwitcherProps) => {
  const pageType = useGetPageType();
  const { locale: routerLocale } = useRouter();
  const currentLocale = storeLocale(routerLocale);

  return (
    <div
      className={clsx(
        styles.localeSwitcher,
        isMobile && styles['localeSwitcher--mobile']
      )}
    >
      {CH_LOCALES.map((locale) => {
        const loc = locale.split('-').at(0)!;
        const href = generateHref(locale, path, pageType, loc);

        return (
          <span
            key={locale}
            aria-label="Change language"
            tabIndex={0}
            role="button"
            onClick={() => {
              window.location.href = `/${href}`;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.location.href = `/${href}`;
              }
            }}
            className={clsx(
              styles.localeSwitcher__navLink,
              currentLocale === locale &&
                styles['localeSwitcher__navLink--active'],
              !isMobile && 'text-utility-utility-small'
            )}
          >
            {loc}
          </span>
        );
      })}
    </div>
  );
};

const ProductLocaleSwitcher = (props: LocaleSwitcherContainerProps) => {
  const handleByLanguage = useProductLocaleSwitcher();

  return <LocaleSwitcher {...props} path={handleByLanguage} />;
};

const CollectionLocaleSwitcher = (props: LocaleSwitcherContainerProps) => {
  const handleByLanguage = useCollectionLocaleSwitcher();

  return <LocaleSwitcher {...props} path={handleByLanguage} />;
};

export const LocaleSwitcherContainer = (
  props: LocaleSwitcherContainerProps
) => {
  const pageType = useGetPageType();
  const { asPath } = useRouter();

  switch (pageType) {
    case 'Product details page':
      return <ProductLocaleSwitcher {...props} />;
    case 'Product listing page':
      return <CollectionLocaleSwitcher {...props} />;
    default:
      return <LocaleSwitcher {...props} path={asPath} />;
  }
};
