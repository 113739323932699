import { useQuery } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import type { ImageType } from '@/components/plpContext/types/storePLP';
import type { CartSettingsQuery } from '@/lib/contentful/__generated__/CartSettingsQuery';
import { useCartSettingsQuery } from '@/lib/contentful/__generated__/CartSettingsQuery';
import type { FilterSettingsQuery } from '@/lib/contentful/__generated__/FilterSettings';
import { useFilterSettingsQuery } from '@/lib/contentful/__generated__/FilterSettings';
import {
  useGetSignUpConfirmedPopupFormPageQuery,
  useGetSignUpPopupFormPageQuery,
} from '@/lib/contentful/__generated__/FormPage';
import type { PaymentIconsQuery } from '@/lib/contentful/__generated__/PaymentIcons';
import { usePaymentIconsQuery } from '@/lib/contentful/__generated__/PaymentIcons';
import { useProductIconsSettingsQuery } from '@/lib/contentful/__generated__/ProductIconsSettings';
import type { ProductIconsSettingsQuery } from '@/lib/contentful/__generated__/ProductIconsSettings';
import { useSocialLinksQuery } from '@/lib/contentful/__generated__/SocialLinks';
import type { SocialLinksQuery } from '@/lib/contentful/__generated__/SocialLinks';
import { useStoreSelectorSettingsQuery } from '@/lib/contentful/__generated__/StoreSelectorSettings';
import type { StoreSelectorSettingsQuery } from '@/lib/contentful/__generated__/StoreSelectorSettings';
import type { SwatchSettingsQuery } from '@/lib/contentful/__generated__/SwatchMapping';
import { useSwatchSettingsQuery } from '@/lib/contentful/__generated__/SwatchMapping';
import { contentfulQueryDataSource } from '@/lib/contentful/dataSources';
import type { StoreLocale } from '@/root/constants';
import { getContentfulTagFilter } from '@/utils/contentfulHelpers';
import {
  getFormPagePopupData,
  type FormPageData,
} from '../formPageContext/FormPageContext';

export type SocialType =
  | 'instagram'
  | 'pinterest'
  | 'facebook'
  | 'youtube'
  | 'twitter'
  | 'tiktok';

export type SocialLink = {
  type: SocialType;
  link: string;
  newTab: boolean;
};

export type StoreFilterSetting = {
  label: string;
  attribute: string;
  searchParameter: string;
  displaySwatches: boolean;
};

export type FiltersType = 'product' | 'nailart' | 'eduCourse';

export type StoreGlobalFilterSettings = {
  product?: StoreFilterSetting[];
  nailart?: StoreFilterSetting[];
  eduCourse?: StoreFilterSetting[];
};

export type StorePaymentIcon = {
  label: string;
  image: string;
};

export type ColorSwatchSetting = {
  image: string;
  colorNames: string[];
};

export type CartSettings = {
  showCartMessage: boolean;
  cartMessage: string;
  isFreeShippingActive: boolean;
  freeShippingAmount: number;
  showCartDiscountCodeBox: boolean;
  seoTitle: string;
  seoDescription: string;
};

export type productIconsSetting = {
  type: string;
  icons: ImageType[];
};

type store = {
  url: string;
  countryName: string;
  countryCode: string;
  currency: string;
};

export type GlobalContentContext = {
  socialLinks?: SocialLink[];
  filterSettings?: StoreGlobalFilterSettings;
  paymentIcons?: StorePaymentIcon[];
  swatchSettings?: ColorSwatchSetting[];
  cartSettings?: CartSettings;
  productIconsSettings?: productIconsSetting[];
  storeSelectorSettings?: store[];
  signUpPopupFormPage?: FormPageData | null;
  signUpConfirmedPopupFormPage?: FormPageData | null;
};

/**
 * Global Content Context
 *
 * @remarks
 * Context provider for content that is required to site furniture components/modals
 */
export const GlobalContentContext = createContext<GlobalContentContext>({});

/**
 * Global Content Provider
 *
 * @remarks
 * Performs data queries using reactQuery. Queries in this provider are shaped using
 * accompanying select functions.
 *
 * @param children - ReactNode
 */
export function GlobalContentProvider({
  locale,
  children,
  byPassCache = false,
}: {
  locale: StoreLocale;
  children: ReactNode;
  byPassCache?: boolean;
}) {
  const { data: socialLinks } = useQuery(
    ['SocialLinks', locale],
    useSocialLinksQuery.fetcher(contentfulQueryDataSource({}), {
      locale,
    }),
    {
      select: socialLinksSelect,
    }
  );

  const { data: filterSettings } = useQuery(
    ['FilterSettings', locale],
    useFilterSettingsQuery.fetcher(contentfulQueryDataSource({}), { locale }),
    {
      select: filterSettingsSelect,
    }
  );

  const { data: paymentIcons } = useQuery(
    ['PaymentIcons', locale],
    usePaymentIconsQuery.fetcher(contentfulQueryDataSource({}), {
      locale,
    }),
    {
      select: paymentIconsSelect,
    }
  );

  const { data: swatchSettings } = useQuery(
    ['SwatchSettings', locale],
    useSwatchSettingsQuery.fetcher(contentfulQueryDataSource({}), {
      locale,
    }),
    {
      select: swatchSettingsSelect,
    }
  );

  const { data: signUpPopupFormPage } = useQuery(
    useGetSignUpPopupFormPageQuery.getKey({ locale }),
    useGetSignUpPopupFormPageQuery.fetcher(
      contentfulQueryDataSource({
        byPassCache,
      }),
      {
        locale,
        tagFilter: getContentfulTagFilter(locale),
      }
    ),
    {
      select: getFormPagePopupData,
    }
  );

  const { data: signUpConfirmedPopupFormPage } = useQuery(
    useGetSignUpConfirmedPopupFormPageQuery.getKey({ locale }),
    useGetSignUpConfirmedPopupFormPageQuery.fetcher(
      contentfulQueryDataSource({ byPassCache }),
      {
        locale,
        tagFilter: getContentfulTagFilter(locale),
      }
    ),
    {
      select: getFormPagePopupData,
    }
  );

  const { data: cartSettings } = useQuery(
    ['CartSettings', locale],
    useCartSettingsQuery.fetcher(contentfulQueryDataSource({}), {
      locale,
    }),
    {
      select: cartSettingsSelect,
    }
  );

  const { data: productIconsSettings } = useQuery(
    useProductIconsSettingsQuery.getKey(),
    useProductIconsSettingsQuery.fetcher(contentfulQueryDataSource({})),
    {
      select: productIconsSettingsSelect,
    }
  );

  const { data: storeSelectorSettings } = useQuery(
    useStoreSelectorSettingsQuery.getKey(),
    useStoreSelectorSettingsQuery.fetcher(contentfulQueryDataSource({})),
    {
      select: storeSelectorSettingsSelect,
    }
  );

  // memoize the full context value
  const contextValue = useMemo(() => {
    return {
      socialLinks,
      filterSettings,
      paymentIcons,
      swatchSettings,
      cartSettings,
      productIconsSettings,
      storeSelectorSettings,
      signUpPopupFormPage,
      signUpConfirmedPopupFormPage,
    };
  }, [
    socialLinks,
    filterSettings,
    paymentIcons,
    swatchSettings,
    cartSettings,
    productIconsSettings,
    storeSelectorSettings,
    signUpPopupFormPage,
    signUpConfirmedPopupFormPage,
  ]);

  return (
    <GlobalContentContext.Provider value={contextValue}>
      {children}
    </GlobalContentContext.Provider>
  );
}

/**
 * Select function that acts as 'clean' to SocialLinksQuery
 *
 * @remarks
 * Data from SocialLinksQuery is reduced into the shape of SocialLink[]
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts SocialLinksQuery
 */
const socialLinksSelect = (
  originalData: SocialLinksQuery
): SocialLink[] | undefined => {
  if (!originalData?.socialLinks?.socialLinksCollection?.items)
    return undefined;

  const links: SocialLink[] = [];

  const items = originalData.socialLinks.socialLinksCollection.items;

  items.forEach((item) => {
    if (!item) return;

    let type: SocialType | null = null;

    switch (item.socialTypes) {
      case 'instagram':
        type = 'instagram';
        break;
      case 'pinterest':
        type = 'pinterest';
        break;
      case 'facebook':
        type = 'facebook';
        break;
      case 'youtube':
        type = 'youtube';
        break;
      case 'twitter':
        type = 'twitter';
        break;
      case 'tiktok':
        type = 'tiktok';
        break;
    }

    if (type) {
      links.push({
        type,
        link: item.link ?? '',
        newTab: item.openLinkInNewTab ?? false,
      });
    }
  });

  if (!links.length) return undefined;

  return links;
};

/**
 * Select function that acts as 'clean' to FilterSettingsQuery
 *
 * @remarks
 * Data from FilterSettingsQuery is reduced into the shape of StoreFilterSetting[]
 * This is then made available as the output of data from useQuery
 *
 * @param originalData - accepts FilterSettingsQuery
 */
const filterSettingsSelect = (
  originalData: FilterSettingsQuery
): StoreGlobalFilterSettings => {
  const filterSettingsObject: StoreGlobalFilterSettings = {};

  const settingsMap = {
    product: 'filtersCollection',
    nailart: 'nailArtFiltersCollection',
    eduCourse: 'eduCourseFiltersCollection',
  } as const;

  Object.entries(settingsMap).forEach(([key, attribute]) => {
    const itemSettings = originalData?.filterSettings?.[attribute];
    const items = itemSettings?.items;

    if (!itemSettings || !items || !items.length) {
      return;
    }

    const filterSettings: StoreFilterSetting[] = [];

    items.forEach((item) => {
      // Ignore the entry if no item or no attribute is set.
      if (!item || !item.attribute) {
        return;
      }

      const filterSetting: StoreFilterSetting = {
        attribute: item.attribute,
        label: item.label ?? '',
        searchParameter: item.searchParameter ?? '',
        displaySwatches: Boolean(item.displaySwatches),
      };

      filterSettings.push(filterSetting);
    });

    if (filterSettings.length) {
      filterSettingsObject[key as FiltersType] = filterSettings;
    }
  });

  return filterSettingsObject;
};

const paymentIconsSelect = (
  originalData: PaymentIconsQuery
): StorePaymentIcon[] | undefined => {
  if (!originalData.paymentIcons?.paymentIconsCollection?.items) {
    return undefined;
  }

  const paymentIcons = [] as StorePaymentIcon[];

  const items = originalData.paymentIcons.paymentIconsCollection.items;

  items.forEach((item) => {
    if (!item || !item.image?.url) {
      return;
    }

    const paymentIcon: StorePaymentIcon = {
      label: item.label ?? '',
      image: item.image.url ?? '',
    };

    paymentIcons.push(paymentIcon);
  });

  return paymentIcons;
};

const swatchSettingsSelect = (
  originalData: SwatchSettingsQuery
): ColorSwatchSetting[] | undefined => {
  if (!originalData?.swatchMapping?.swatches?.items) {
    return;
  }

  const colorSwatchSettings = [] as ColorSwatchSetting[];

  const items = originalData.swatchMapping.swatches.items;

  items.forEach((item) => {
    if (!item || !item.image?.url) {
      return;
    }

    const colorNames: string[] = [];

    if (item.colourName) {
      colorNames.push(item.colourName);
    }

    if (
      Array.isArray(item.additionalColourNames) &&
      item?.additionalColourNames?.length
    ) {
      item.additionalColourNames.forEach((colorName) => {
        if (colorName) {
          colorNames.push(colorName);
        }
      });
    }

    const sanitizedColorNames = colorNames.map((color) =>
      color.trim().toLowerCase()
    );

    colorSwatchSettings.push({
      image: item.image.url,
      colorNames: sanitizedColorNames,
    });
  });

  return colorSwatchSettings;
};

const cartSettingsSelect = (
  originalData: CartSettingsQuery
): CartSettings | undefined => {
  if (!originalData.cartSettingsCollection?.items?.[0]) {
    return;
  }

  const cartSettings = originalData.cartSettingsCollection?.items[0];

  return {
    showCartMessage: cartSettings?.showCartMessage ?? false,
    cartMessage: cartSettings?.cartMessage ?? '',
    isFreeShippingActive: cartSettings.isFreeShippingActive ?? false,
    freeShippingAmount: cartSettings.freeShippingAmount ?? 0,
    showCartDiscountCodeBox: cartSettings.showCartDiscountCodeBox ?? false,
    seoTitle: cartSettings.seoTitle ?? '',
    seoDescription: cartSettings.seoDescription ?? '',
  };
};

const productIconsSettingsSelect = (
  originalData: ProductIconsSettingsQuery
) => {
  if (
    !originalData?.productIconsSettingsCollection?.items[0]?.productTypeIcons
      ?.items
  ) {
    return;
  }

  const productIconsSettings: productIconsSetting[] = [];

  const rawProductIconsSetting =
    originalData?.productIconsSettingsCollection?.items[0]?.productTypeIcons
      .items;

  rawProductIconsSetting.forEach((item) => {
    if (!item?.productType || !item.icons?.items.length) return;

    const icons: ImageType[] = [];

    item.icons?.items.forEach((icon) => {
      if (!icon?.url) return;

      icons.push({
        url: icon.url,
        title: icon.title ?? '',
        height: icon.height ?? 0,
        width: icon.width ?? 0,
      });
    });

    productIconsSettings.push({
      type: item?.productType ?? '',
      icons,
    });
  });

  return productIconsSettings;
};

const storeSelectorSettingsSelect = (
  originalData: StoreSelectorSettingsQuery
) => {
  if (!originalData?.storeSelectorSettingsCollection?.items[0]?.stores?.data) {
    return;
  }

  const stores: store[] = [];

  const rawStoresData =
    originalData?.storeSelectorSettingsCollection?.items[0]?.stores.data;

  rawStoresData.forEach((item) => {
    if (!item?.countryCode || !item?.countryName || !item?.url) return;

    stores.push({
      url: item.url,
      countryName: item.countryName ?? '',
      countryCode: item.countryCode ?? '',
      currency: item.currency ?? '',
    });
  });

  return stores;
};
