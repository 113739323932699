import { i18n } from '@lingui/core';
import { DesktopMenuMain } from '@we-make-websites/ui-lib';
import IconHeart from '@we-make-websites/ui-lib/src/icons/misc/heart.svg';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import {
  useBoundingclientrectRef,
  useOnWindowScroll,
  useOnWindowResize,
} from 'rooks';
import { useContext, useContextSelector } from 'use-context-selector';
import { useFeatureFlags } from '@/components/featureFlagsProvider/FeatureFlagsContext';
import { MenuContext } from '@/components/menuContext/MenuContext';
import { OverlayContext } from '@/components/overlay/OverlayContext';
import OverlayFallback from '@/components/overlay/OverlayFallback';
import { PromoBanner } from '@/components/promoBanner/PromoBanner';
import IconAccount from '@/icons/misc/account.svg';
import IconCart from '@/icons/misc/cart.svg';
import IconLogo from '@/icons/misc/logo.svg';
import IconSearch from '@/icons/misc/search.svg';
import { storeLocale } from '@/root/constants';
import { CartContext } from '../cartContext/CartContext';
import KeyboardAccessibility from '../keyboardAccessibility/KeyboardAccessibility';
import { MenuDrawerCover } from '../menuDrawerCover/MenuDrawerCover';
import styles from './SiteHeader.module.scss';

const Overlay = dynamic(() => import('@/components/overlay/Overlay'), {
  ssr: false,
});

const MiniCartContainer = dynamic(
  () => import('@/components/miniCartContainer/MiniCartContainer'),
  {
    ssr: false,
  }
);

const SearchMenu = dynamic(() => import('@/components/searchMenu/SearchMenu'), {
  ssr: false,
  /**
   * A basic loading component.
   */
  loading: () => <div className={styles.siteHeader__searchMenuLoader} />,
});

type HeaderCSSVars = {
  header: string;
  visible: string;
  promo: string;
};

/**
 * SiteHeader Component - Global component.
 */
export const SiteHeader = () => {
  const router = useRouter();
  const isHomePage = router.pathname === '/' || router.pathname === '/_pro';
  const [loadMenu, setLoadMenu] = useState(false);
  const [promoCollapsed, setPromoCollapsed] = useState(false);
  const [loadSearchMenu, setLoadSearchMenu] = useState(false);
  const buttonClass = 'icon-button';
  const cssVars: HeaderCSSVars = {
    header: '--header-height',
    visible: '--header-height-visible',
    promo: '--header-transition-offset',
  } as const;
  const { locale: routerLocale } = router;
  const locale = storeLocale(routerLocale);

  const { featureFlags } = useFeatureFlags();
  const { cartEnabled } = featureFlags[locale];

  const promoBanner = useContextSelector(
    MenuContext,
    ({ promoBanner }) => promoBanner
  );
  const promoExists = promoBanner && (promoBanner.promos || []).length > 0;

  // Select Currently Active Header
  const isActive = useContextSelector(
    OverlayContext,
    ({ active }) => active[0] === 'menuDrawer'
  );

  const isSearchMenuActive = useContextSelector(
    OverlayContext,
    ({ active }) => active[0] === 'searchMenu'
  );

  const isCartDrawerActive = useContextSelector(
    OverlayContext,
    ({ active }) => active[0] === 'cartDrawer'
  );

  const desktopMainMenu =
    useContextSelector(MenuContext, ({ menus }) => menus?.desktop) || [];

  const cartItems = useContextSelector(CartContext, ({ cart }) => cart?.lines);
  const cartItemsCount = cartItems?.reduce((prev, next) => {
    return prev + next.quantity;
  }, 0);

  // Toggle Active Header
  const { toggle: toggleOverlay } = useContext(OverlayContext);

  // Set scrollbar width.
  // Help full bleed section to have accurate width instead of 100vw.
  const setScrollbarWidth = () => {
    if (typeof window === 'undefined') return;

    window.document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.body.offsetWidth}px`
    );
  };

  setScrollbarWidth();
  useOnWindowResize(setScrollbarWidth);

  // Watch header bounding box and return current height
  const [headerRef, boundingClientHeader] = useBoundingclientrectRef();
  const headerHeight = boundingClientHeader?.height ?? 0;

  // On header height change set CSS variable
  useEffect(() => {
    if (headerHeight > 0)
      document.documentElement.style.setProperty(
        cssVars.header,
        `${headerHeight}px`
      );
  }, [headerHeight]);

  // Watch promo banner bounding box and return current height
  const [promoRef, boundingClientPromo] = useBoundingclientrectRef();
  const promoHeight = promoExists ? boundingClientPromo?.height ?? 0 : 0;

  // On promo banner height change set CSS variable
  useEffect(() => {
    const offset = window.scrollY <= 10 ? promoHeight : 0;

    const visibleHeader =
      window.scrollY <= 10 ? headerHeight : headerHeight - promoHeight;

    document.documentElement.style.setProperty(cssVars.promo, `${offset}px`);
    document.documentElement.style.setProperty(
      cssVars.visible,
      `${visibleHeader}px`
    );
  }, [promoHeight, headerHeight]);

  return (
    <>
      {typeof window !== 'undefined' && (
        <ScrollDetector
          {...{ setPromoCollapsed, cssVars, promoHeight, headerHeight }}
        />
      )}
      <KeyboardAccessibility />
      <div
        ref={headerRef}
        className={clsx(styles.siteHeader, {
          [styles.promoCollapsed]: promoCollapsed,
        })}
      >
        <section className={styles.siteHeader__promo} ref={promoRef}>
          {promoExists && (
            <div className={styles.siteHeader__promoContainer}>
              <PromoBanner />
            </div>
          )}
        </section>
        <section className={styles.siteHeader__masthead} ref={headerRef}>
          <div className={clsx(styles.siteHeader__container, 'container')}>
            <div className={styles.siteHeader__grid}>
              <button
                className={clsx(styles.siteHeader__menu, buttonClass)}
                aria-label={i18n._(
                  /* i18n */ {
                    id: 'general.site_header.menu',
                    message: 'Menu',
                  }
                )}
                onClick={() => {
                  // If menu component has not yet been loaded set to load
                  if (!loadMenu) {
                    setLoadMenu(true);
                  }

                  toggleOverlay({
                    ignoreDismissed: true,
                    namespace: 'menuDrawer',
                  });
                }}
                data-test-id="siteHeaderMenu"
              >
                <span
                  className={clsx(styles.hamburger, {
                    [styles.isActive]: isActive,
                  })}
                >
                  <span className={styles.hamburger__inner} />
                </span>
              </button>

              <Link
                href="/"
                className={styles.siteHeader__logo}
                aria-label={i18n._(
                  /* i18n */ {
                    id: 'general.site_header.logo',
                    message: 'OPI - Los Angeles',
                  }
                )}
              >
                {isHomePage && <h1 className="visually-hidden"> OPI </h1>}
                <IconLogo />
              </Link>

              <DesktopMenuMain desktopMainMenu={desktopMainMenu} />

              <nav className={styles.siteHeader__controls}>
                <button
                  className={clsx(styles.siteHeader__search, buttonClass)}
                  aria-label={i18n._(
                    /* i18n */ {
                      id: 'general.site_header.search',
                      message: 'Search',
                    }
                  )}
                  onMouseOver={() => setLoadSearchMenu(true)}
                  onFocus={() => setLoadSearchMenu(true)}
                  onClick={() => {
                    toggleOverlay({
                      ignoreDismissed: true,
                      namespace: 'searchMenu',
                    });
                  }}
                >
                  <IconSearch />
                </button>

                <Link
                  href="/account/wishlist"
                  className={clsx(styles.siteHeader__wishlist, buttonClass)}
                >
                  <IconHeart />
                </Link>

                <Link
                  href="/account/login"
                  className={clsx(styles.siteHeader__account, buttonClass)}
                  aria-label={i18n._(
                    /* i18n */ {
                      id: 'general.site_header.account',
                      message: 'Account',
                    }
                  )}
                >
                  <IconAccount />
                </Link>

                {cartEnabled && (
                  <button
                    className={clsx(styles.siteHeader__cart, buttonClass)}
                    aria-label={i18n._(
                      /* i18n */ {
                        id: 'general.site_header.cart',
                        message: 'Cart',
                      }
                    )}
                    onClick={() => {
                      toggleOverlay({
                        ignoreDismissed: true,
                        namespace: 'cartDrawer',
                      });
                    }}
                    data-test-id="siteHeaderCart"
                  >
                    <IconCart />

                    {(cartItemsCount || 0) > 0 && (
                      <div className={styles.siteHeader__cartCount}>
                        <p className={clsx(styles.siteHeader__cartCountText)}>
                          {cartItemsCount}
                        </p>
                      </div>
                    )}
                  </button>
                )}
              </nav>
            </div>
          </div>
        </section>
      </div>
      {!loadMenu && (
        <OverlayFallback>
          <MenuDrawerCover />
        </OverlayFallback>
      )}
      {loadMenu && (
        <Overlay
          type={'drawer'}
          blockClass={'menuDrawerOverlay'}
          direction={'right'}
          namespace={'menuDrawer'}
          template={false}
        >
          <MenuDrawerCover />
        </Overlay>
      )}
      {loadSearchMenu && (
        <Overlay
          type={'modal'}
          namespace={'searchMenu'}
          blockClass={'searchMenuOverlay'}
          template={false}
          wide
        >
          {isSearchMenuActive && <SearchMenu />}
        </Overlay>
      )}
      {isCartDrawerActive && (
        <Overlay
          type={'drawer'}
          direction={'left'}
          namespace={'cartDrawer'}
          blockClass={'cartDrawerOverlay'}
          template={false}
        >
          <MiniCartContainer />
        </Overlay>
      )}
    </>
  );
};

const ScrollDetector = ({
  setPromoCollapsed,
  cssVars,
  promoHeight,
  headerHeight,
}: {
  setPromoCollapsed: (state: boolean) => void;
  cssVars: HeaderCSSVars;
  promoHeight: number;
  headerHeight: number;
}) => {
  useOnWindowScroll(() => {
    // 10 bellow might be pulled from some client settings.
    if (window.scrollY <= 10) {
      setPromoCollapsed(false);

      document.documentElement.style.setProperty(cssVars.promo, '0px');
      document.documentElement.style.setProperty(
        cssVars.visible,
        `${headerHeight}px`
      );
    } else {
      setPromoCollapsed(true);

      document.documentElement.style.setProperty(
        cssVars.promo,
        `${promoHeight}px`
      );

      document.documentElement.style.setProperty(
        cssVars.visible,
        `${headerHeight - promoHeight}px`
      );
    }
  });
  return null;
};

SiteHeader.displayName = 'SiteHeader';

export default SiteHeader;
