/* eslint-disable @typescript-eslint/naming-convention */
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { createContext } from 'use-context-selector';
import { arrayPushPosition } from '@/utils/state';

type Overlay = {
  namespace: string;
  index?: number;
  active?: boolean;
  clearQueue?: boolean;
  component?: 'string';
  ignoreDismissed?: boolean;
  stuck?: boolean;
};

export type OverlayContext = {
  active: string[];
  dismissed: string[];
  stuck: string;
  close(namespace: string, dismiss?: boolean): void;
  closeAll(dismiss?: boolean): void;
  open(overlay: Overlay): void;
  queue(overlay: Overlay): void;
  setDismissed(namespace: string): void;
  setStuck(overlay?: Overlay): void;
  toggle(overlay?: Overlay): void;
};

export const OverlayContext = createContext<OverlayContext>(
  {} as OverlayContext
);

export function OverlayProvider({ children }: { children: ReactNode }) {
  const [active, mutateActive] = useState<string[]>([]);
  const [dismissed, mutateDismissed] = useState<string[]>([]);
  const [stuck, mutateStuck] = useState('');

  const getOverlayIndexByNamespace = useCallback(
    (namespace: string) => {
      return active.indexOf(namespace);
    },
    [active]
  );

  /**
   * Mutations
   */
  const SET_CLOSE_ALL = () => {
    mutateActive([]);
  };

  const SET_DISMISSED = useCallback(
    (namespace: string) => {
      mutateDismissed([...dismissed, namespace]);
    },
    [dismissed]
  );

  const SET_OPEN = useCallback(
    ({
      clearQueue = true,
      ignoreDismissed = false,
      index = -1,
      namespace,
    }: {
      clearQueue: boolean;
      ignoreDismissed: boolean;
      index: number;
      namespace: string;
    }) => {
      if (
        dismissed.includes(namespace) &&
        !ignoreDismissed
        // && !cnvs.settings.disableOverlaysDismissed
      ) {
        return;
      }

      if (clearQueue) {
        mutateActive([namespace]);
        return;
      }

      if (index > -1) {
        mutateActive(arrayPushPosition(active, namespace, 0));
        return;
      }

      mutateActive([namespace, ...active]);
    },
    [active, dismissed]
  );

  const SET_QUEUE = useCallback(
    ({
      ignoreDismissed = false,
      index = -1,
      namespace,
    }: {
      ignoreDismissed: boolean;
      index: number;
      namespace: string;
    }) => {
      const matchedDismissed = dismissed.find((dismissed) => {
        return dismissed === namespace;
      });

      if (
        matchedDismissed &&
        !ignoreDismissed
        // && !cnvs.settings.disableOverlaysDismissed
      ) {
        return;
      }

      if (index > -1) {
        active[index] = namespace;
        return;
      }

      mutateActive([...active, namespace]);
    },
    [active, dismissed]
  );

  const SET_STUCK = ({
    namespace = '',
    stuck = false,
  }: {
    namespace?: string;
    stuck?: boolean;
  }) => {
    mutateStuck(namespace && stuck ? namespace : '');
  };

  /**
   * Actions
   */
  const close = (namespace: string, dismiss = false) => {
    const namespaceIsStuck = namespace && stuck === namespace;
    const noNamespaceProvidedWhenStuck = !namespace && stuck;

    if (namespaceIsStuck || noNamespaceProvidedWhenStuck) return;

    const dismissed = [...active];

    if (namespace) {
      const index = getOverlayIndexByNamespace(namespace);

      if (index > -1) {
        dismissed.splice(index, 1);
        mutateActive([...dismissed]);
      }
    } else {
      dismissed.splice(0, 1);
      mutateActive([...dismissed]);
    }

    if (!dismiss) {
      return;
    }

    setStuck();
    setDismissed(dismissed[0]);
  };

  const open = (overlay: Overlay) => {
    const index = getOverlayIndexByNamespace(overlay.namespace);

    if (stuck && stuck !== overlay.namespace) {
      SET_QUEUE({
        ignoreDismissed: false,
        ...overlay,
        index,
      });
      return;
    }

    SET_OPEN({ clearQueue: false, ignoreDismissed: false, ...overlay, index });

    /**
     * Update stuck state.
     */
    setStuck(overlay);

    /**
     * If has component property then set loading state.
     */
    if (!overlay.component) {
      return;
    }
  };

  const queue = (overlay: Overlay) => {
    SET_QUEUE({
      ignoreDismissed: false,
      ...overlay,
      index: getOverlayIndexByNamespace(overlay.namespace),
    });

    /**
     * If component then set loading state.
     */
    if (!overlay.component) {
      return;
    }
  };

  const closeAll = (dismiss = true) => {
    if (stuck) {
      return;
    }

    if (active.length && dismiss) {
      setDismissed(active[0]);
    }

    setStuck();
    SET_CLOSE_ALL();
  };

  const setDismissed = (namespace: string) => {
    if (dismissed.includes(namespace)) {
      return;
    }
    SET_DISMISSED(namespace);
  };

  const setStuck = (overlay?: Overlay) => {
    if (!overlay) return;
    SET_STUCK({ ...overlay });
  };

  const toggle = (overlay: Overlay) => {
    if (getOverlayIndexByNamespace(overlay.namespace) >= 0) {
      close(overlay.namespace);
      return;
    }

    open(overlay);
  };

  return (
    <OverlayContext.Provider
      value={{
        active,
        dismissed,
        stuck,
        close,
        closeAll,
        open,
        queue,
        setDismissed,
        setStuck,
        toggle,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
}
