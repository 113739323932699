import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { CSSProperties } from 'react';
import { useContext } from 'use-context-selector';
import { CustomerContext } from '@/components/customerContext/CustomerContext';
import { LocaleSwitcherContainer } from '@/components/localeSwitcher/LocaleSwitcher';
import { MenuContext } from '@/components/menuContext/MenuContext';
import type { StorePromoBannerItem } from '@/components/menuContext/MenuContext';
import IconChevronLeft from '@/icons/direction/arrow/left.svg';
import IconChevronRight from '@/icons/direction/arrow/right.svg';
import IconLocation from '@/icons/misc/location.svg';
import IconPhone from '@/icons/misc/phone.svg';
import IconProLogin from '@/icons/misc/pro-login.svg';
import { ActionKind, usePony } from '@/utils/hooks/use-pony';
import { useGetSalonFinderRoute } from '@/utils/hooks/useGetSalonFinderRoute';
import type { StoreLocale } from '@store-constants';
import {
  isChLocale,
  storeLocale,
  WELLASTORE_PROFESSIONAL_URL,
} from '@store-constants';
import styles from './PromoBanner.module.scss';

/**
 * PromoBanner Component - Site header.
 */
export const PromoBanner = () => {
  const { proCustomer } = useContext(CustomerContext);
  const { promoBanner, promoBannerProContent } = useContext(MenuContext);
  const bannerData = proCustomer ? promoBannerProContent : promoBanner;

  if (!bannerData || !bannerData.backgroundColor) return null;

  const { backgroundColor, textColor } = bannerData;
  const promos = proCustomer
    ? promoBannerProContent?.promos
    : promoBanner?.promos;

  return (
    <PromoCarousel
      promos={promos ?? []}
      backgroundColor={backgroundColor}
      textColor={textColor}
    />
  );
};

const PromoCarousel = ({
  promos,
  backgroundColor,
  textColor,
}: {
  promos: StorePromoBannerItem[];
  backgroundColor: string;
  textColor: string;
}) => {
  const {
    getSectionProps,
    getHeadingProps,
    getCarouselWrapperProps,
    getCarouselProps,
    getCarouselItemProps,
    getButtonProps,
    getAnnouncerProps,
    state,
  } = usePony({ numItems: promos.length });

  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const { localizedSalonFinderRoute } = useGetSalonFinderRoute();
  const isPromoCarousel = promos.length > 1;

  const telephoneLink = `tel:${t({
    id: 'header.promoBanner.phone',
    message: '1-800-935-5273',
  })}`;

  const renderLocaleSwitcher = () => {
    if (!isChLocale(locale)) {
      return null;
    }

    return <LocaleSwitcherContainer />;
  };

  const renderPhone = () => {
    const supportedLocales: StoreLocale[] = ['en-US', 'en-GB'];
    if (!supportedLocales.includes(locale)) {
      return null;
    }

    return (
      <div
        className={clsx(
          styles.promoBanner__sideNumberWrapper,
          styles.promoBanner__sideSingleNavWrapper
        )}
      >
        <a
          href={telephoneLink}
          className={clsx(
            styles.promoBanner__navLink,
            'text-utility-utility-small'
          )}
        >
          <IconPhone />
          <span className="visually-hidden">
            <Trans id="header.promoBanner.phoneLabel" message="Phone number" />
          </span>
          <Trans id="header.promoBanner.phone" message="1-800-935-5273" />
        </a>
      </div>
    );
  };

  return (
    <section
      style={
        {
          '--banner-text-color': textColor,
          '--banner-background-color': backgroundColor,
        } as CSSProperties
      }
      {...getSectionProps()}
      className={styles.promoBanner}
    >
      <div className={clsx(styles.promoBanner__container, 'container')}>
        <div className={styles.promoBanner__leftContainer}>
          {renderLocaleSwitcher()}
        </div>
        <div
          className={clsx(styles.promoBanner__carouselWrapper, 'col xs4 l5-8')}
        >
          <span
            {...getHeadingProps()}
            className="visually-hidden"
            aria-hidden="true"
          >
            <Trans id="header.promoBanner.title" message="Promotional Offers" />
          </span>

          <div className={styles.promoBanner__carousel}>
            <div
              {...getCarouselWrapperProps()}
              className={styles.promoBanner__carouselWrapper}
            >
              {promos.length > 1 ? (
                <ul
                  {...getCarouselProps()}
                  className={styles.promoBanner__carouselList}
                >
                  {promos.map((promoBanner, index) => (
                    <li
                      key={index}
                      {...getCarouselItemProps(index)}
                      className={styles.promoBanner__slide}
                      data-test-id="promoBanner"
                    >
                      <Link
                        href={promoBanner.link}
                        aria-hidden={getCarouselItemProps(index)['aria-hidden']}
                        className={clsx(
                          styles.promoBanner__link,
                          'text-body-small'
                        )}
                        style={{
                          visibility:
                            !getCarouselItemProps(index)['aria-hidden'] ||
                            state.isAnimating
                              ? 'visible'
                              : 'hidden',
                        }}
                        target={promoBanner.newTab ? '_blank' : undefined}
                      >
                        {promoBanner.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <Link
                  href={promos[0].link}
                  className={clsx(
                    styles.promoBanner__link,
                    styles.promoBanner__linkSingle,
                    'text-body-small'
                  )}
                >
                  {promos[0].text}
                </Link>
              )}
            </div>
          </div>

          <button
            {...getButtonProps(ActionKind.Previous)}
            className={clsx(
              styles.promoBanner__control,
              styles.promoBanner__controlPrevious,
              styles.promoBanner__icons,
              styles.promoBanner__fill
            )}
            style={{
              visibility: isPromoCarousel ? 'visible' : 'hidden',
            }}
            disabled={!isPromoCarousel}
          >
            <IconChevronLeft />
          </button>

          <button
            {...getButtonProps(ActionKind.Next)}
            className={clsx(
              styles.promoBanner__control,
              styles.promoBanner__controlNext,
              styles.promoBanner__icons,
              styles.promoBanner__fill
            )}
            data-test-id="promoBannerControlNext"
            style={{
              visibility: isPromoCarousel ? 'visible' : 'hidden',
            }}
            disabled={!isPromoCarousel}
          >
            <IconChevronRight />
          </button>

          <div {...getAnnouncerProps()}>
            <p>{`Item ${state.activeSlideIndex + 1} of ${promos.length}`}</p>
          </div>
        </div>

        <div
          className={[styles.promoBanner__sideNavWrapper, 'col xs l9-12'].join(
            ' '
          )}
        >
          <div
            className={clsx(
              styles.promoBanner__sideSalonFinderWrapper,
              styles.promoBanner__sideSingleNavWrapper
            )}
          >
            <Link
              href={localizedSalonFinderRoute}
              className={clsx(
                styles.promoBanner__navLink,
                'text-utility-utility-small'
              )}
            >
              <IconLocation />
              <Trans
                id="header.promoBanner.salonFinder"
                message="Salon Finder"
              />
            </Link>
          </div>
          {renderPhone()}
          <div
            className={clsx(
              styles.promoBanner__sideLoginWrapper,
              styles.promoBanner__sideSingleNavWrapper
            )}
          >
            <Link
              href={
                isChLocale(locale) || locale === 'en-GB'
                  ? WELLASTORE_PROFESSIONAL_URL['promo-banner'][locale]
                  : WELLASTORE_PROFESSIONAL_URL[locale]
              }
              className={clsx(
                styles.promoBanner__navLink,
                'text-utility-utility-small'
              )}
            >
              <IconProLogin />
              <Trans id="header.promoBanner.proLogin" message="PRO LOG IN" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
